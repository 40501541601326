import { forwardRef, useImperativeHandle } from 'react'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { BlockQuote, Stack, Text, Button, Dialog, Icons, Row } from '../../../components'
import { mutations } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'

export const PaymentUI = forwardRef(({ user }, ref) => {
  useImperativeHandle(ref, () => ({}))

  return (
    <Stack spacing={2}>
      <Text.Body>Prepare a cheque with the information below to be mailed to GiveWise Foundation Canada.</Text.Body>
      <BlockQuote>
        <Text.Bold>Payee</Text.Bold>
        <Text.Body>GiveWise Foundation Canada</Text.Body>
        <Text.Bold>Memo (Must be included)</Text.Bold>
        <Text.Body>Reference #{user.funds[0].number}</Text.Body>
        <Text.Bold>Mailing Address</Text.Bold>
        <Text.Body>
          3251 Boxwood Court <br />
          Abbotsford BC <br />
          V3G 0A6
        </Text.Body>
      </BlockQuote>

      <Text.Body>
        Your contribution will post to your account as soon as your cheque clears; however, your tax receipt will
        reflect the post mark date on the envelope.
      </Text.Body>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ client, fundId, giftDetails, userId }) {
  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createGuestContributionRecord,
      variables: {
        userId,
        fundId,
        amount: amounts.total,
        paymentType: 2,
        description: '',
        taxReceipted: true,
        charityId: giftDetails.charityId,
        usedGiveGenius: true,
      },
    })
    .then(({ data }) => data.createGuestContributionRecord)

  return [contribution]
}

export function SuccessUI({ me, user, grantTotal, charityId, charityName }) {
  const name = me?.name ?? `${user.firstName} ${user.lastName}` ?? 'Guest'

  const navigate = useNavigate()

  const handleSignUpClick = () => {
    navigate('/sign-up')
  }
  return (
    <Dialog open hideCloseButton>
      <Row alignItems="center" spacing={4}>
        <Icons.Success sx={{ fontSize: '3rem' }} color="success" />
        <DialogTitle
          sx={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100%' }}
        >
          <Typography variant="h5">One More Step! Action Required to Complete Donation.</Typography>
        </DialogTitle>
      </Row>

      <DialogContent>
        <Text.Body sx={{ mb: 1 }}>
          Thank you for your donation to{' '}
          <strong>
            {charityName} ({charityId})
          </strong>
          . Your generosity is making a meaningful impact, and we&apos;re so grateful for your support.
        </Text.Body>
        <Text.Body sx={{ mb: 3 }}>
          You will now need to mail a cheque in the amount of <strong>${grantTotal.toFixed(2)}</strong> to GiveWise
          order to complete this transaction.
        </Text.Body>
        <Text.Body sx={{ mb: 2 }}>
          <strong>Here are the instructions for preparing and sending a cheque:</strong>
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Payee:</strong> GiveWise Foundation Canada
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Value of Cheque:</strong> ${grantTotal.toFixed(2)}
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Memo (Must be included):</strong> Reference #{user.funds[0].number}
        </Text.Body>
        <Text.Body>
          <strong>Mail Cheque To:</strong>
        </Text.Body>
        <Text.Body>3251 Boxwood Court</Text.Body>
        <Text.Body>Abbotsford BC</Text.Body>
        <Text.Body sx={{ mb: 3 }}>V3G 0A6</Text.Body>

        <Text.Body sx={{ mb: 3 }}>
          Once the cheque has been received and has cleared, the gift will be sent and you will receive an email
          notification.
        </Text.Body>
        <Text.H6 sx={{ mb: 1 }}>
          <strong>Make Giving Easier - Create Your Giving Fund Today</strong>
        </Text.H6>
        <Text.Body>Add a password and set up your Giving Fund to:</Text.Body>
        <ul>
          <li>
            <Text.Body>Instantly access a single tax receipt for all your donations, anytime.</Text.Body>
          </li>
          <li>
            <Text.Body>Set up recurring gifts with ease.</Text.Body>
          </li>
          <li>
            <Text.Body>Organize and manage all your donations in one convenient place.</Text.Body>
          </li>
        </ul>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSignUpClick}>
          Add Password
        </Button>
      </DialogActions>
    </Dialog>
  )
}
