import { gql } from '@apollo/client'

export const initiateCreditCard = gql`
  mutation initiateCreditCard {
    initiateCreditCard
  }
`

export const finalizeCreditCard = gql`
  mutation finalizeCreditCard($data: FinalizeCreditCardInput!) {
    finalizeCreditCard(data: $data) {
      id
      paymentType
      details
      multiUse
      status
      default
    }
  }
`

export const addLinkedBank = gql`
  mutation addLinkedBank($data: CreateEftPaymentMethodInput!) {
    addLinkedBank(data: $data) {
      id
      paymentType
      details
      multiUse
      status
      default
    }
  }
`

export const createZumRailsUser = gql`
  mutation createZumRailsUser($data: CreateZumRailsUserInput!) {
    createZumRailsUser(data: $data)
  }
`
export const createZumRailsManualUser = gql`
  mutation createZumRailsManualUser($data: CreateZumRailsManualInput!) {
    createZumRailsManualUser(data: $data)
  }
`

export const deleteBankAccount = gql`
  mutation deleteBankAccount($data: DeleteEftPaymentMethodInput!) {
    deleteBankAccount(data: $data)
  }
`

export const charityAddLinkedBank = gql`
  mutation charityAddLinkedBank($data: CreateEftPaymentMethodInput!) {
    charityAddLinkedBank(data: $data) {
      id
      paymentType
      details
      multiUse
      status
      default
    }
  }
`

export const addManualBankAccount = gql`
  mutation addManualBankAccount($data: CreateManualBankAccountInput!) {
    addManualBankAccount(data: $data) {
      id
      paymentType
      details
      status
      default
    }
  }
`

export const deactivateCharityPaymentMethod = gql`
  mutation deactivateCharityPaymentMethod($id: Int!) {
    deactivateCharityPaymentMethod(id: $id) {
      id
    }
  }
`

export const deactivateCreditCard = gql`
  mutation deactivateCreditCard($deactivateCreditCardInput: DeactivateCreditCardInput!) {
    deactivateCreditCard(DeactivateCreditCardInput: $deactivateCreditCardInput)
  }
`
