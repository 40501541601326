import { forwardRef, useImperativeHandle } from 'react'
import { GridToolbarContainer, GridToolbarExport, DataGrid as MuiDataGrid, useGridApiContext } from '@mui/x-data-grid'
import { Tooltip } from '@mui/material'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'

import { Box, Divider, Icons, LinearProgress, Text, ToggleButtons } from '..'
import { TroubleMessage } from '../../pages/charities/trouble-message'

export function DataGridToolbar({
  filters,
  title,
  disableExport,
  csvOptions,
  loading,
  viewToggleOnClicks,
  viewToggleState,
  showTroubleMessage,
  ...props
}) {
  /* when no title is provided, show filters in place of the title next to the export button,
   * instead of putting filters on its own row */
  return (
    <>
      <GridToolbarContainer {...props} sx={{ flexWrap: 'wrap', justifyContent: 'space-between', mb: 2 }}>
        {title ? <Text.H2>{title}</Text.H2> : filters}
        {viewToggleOnClicks && viewToggleState && (
          <ToggleButtons onClicks={viewToggleOnClicks} viewToggleState={viewToggleState} />
        )}
        {showTroubleMessage && <TroubleMessage />}
        {!disableExport && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
              marginLeft: 'auto',
            }}
          >
            <GridToolbarExport
              csvOptions={csvOptions}
              printOptions={{ disableToolbarButton: true }}
              sx={{
                fontSize: '16px',
                px: 3,
                py: 1,
              }}
            />
            <Tooltip
              title="Detailed Report includes: gift designations, processing fees, courtesy fees, and more"
              arrow
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: 0,
                  padding: 0,
                }}
              >
                <Icons.FaIcon icon={faCircleQuestion} />
                <Text.Body sx={{ ml: 0.5, fontSize: '14px' }}>Download a detailed activity report</Text.Body>
              </div>
            </Tooltip>
          </Box>
        )}
        {title && filters && <Box width="100%">{filters}</Box>}
      </GridToolbarContainer>
      {loading && <LinearProgress />}
      <Divider light />
    </>
  )
}

export const DataGrid = forwardRef(
  (
    {
      apiRef,
      filters,
      sx,
      components,
      componentsProps,
      title,
      disableExport,
      loading,
      pageSize,
      rowHeight,
      autoRowHeight = !rowHeight,
      exportFileName,
      viewToggleOnClicks,
      viewToggleState,
      showTroubleMessage,
      hideColumnHeaders,
      ...props
    },
    ref
  ) => (
    <MuiDataGrid
      ref={ref}
      pageSize={pageSize ?? 20}
      autoHeight
      disableSelectionOnClick
      ignoreSelectionOnExport
      {...(autoRowHeight && !rowHeight && { getRowHeight: () => 'auto' })}
      {...(rowHeight && { rowHeight })}
      sx={{
        width: '100%',
        border: 'none',
        ...(hideColumnHeaders && {
          '.MuiDataGrid-columnHeaders': {
            display: 'none',
          },
          '.MuiDataGrid-virtualScroller': {
            marginTop: '0px !important',
          },
        }),
        '.MuiDataGrid-columnHeadersWrapper': {
          height: 0,
        },
        // '& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-columnHeaderTitleContainer': {
        //   fontWeight: 600,
        //   fontSize: '1rem',
        // },
        '& .MuiDataGrid-toolbarContainer': {
          padding: 0,
        },
        '& .MuiDataGrid-overlay': {
          opacity: 0.9,
          backgroundColor: 'white',
          width: '100%',
          position: 'relative',
          zIndex: 10,
        },
        ...(autoRowHeight && {
          '& .MuiDataGrid-cell': {
            py: '6px',
          },
        }),
        ...sx,
      }}
      components={{
        Toolbar: (p) => {
          /* This is a werid hack to make the apiRef property work on DataGrid.
           *
           * Docs say: https://mui.com/x/react-data-grid/api-object/#how-to-use-the-api-object
           *
           * > The API object is accessible through the apiRef variable.
           * > Depending on where you are trying to access this variable,
           * > you will have to use either useGridApiContext or useGridApiRef
           *
           * It looks like there's an useGridApiRef() and apiRef property
           * similar to react's useRef() and ref property, but it doesn't seem
           * to work and it's not very well documented so I have no idea. But I
           * just learned about useImperativeHandle() and since useGridApiContext()
           * _appears_ to work, we can just call that inside this "component"
           * and forward it to apiRef property using react's useRef() ... */

          if (exportFileName) {
            const { csvOptions } = p
            csvOptions.fileName = exportFileName
          }

          const gridApi = useGridApiContext()
          useImperativeHandle(apiRef, () => gridApi.current)
          return DataGridToolbar(p)
        },
        ...components,
      }}
      componentsProps={{
        toolbar: {
          csvOptions: { allColumns: true },
          title,
          disableExport,
          loading,
          filters,
          viewToggleOnClicks,
          viewToggleState,
          showTroubleMessage,
          rowHeight,
          hideColumnHeaders,
        },
        ...componentsProps,
      }}
      loading={loading}
      {...props}
    />
  )
)
