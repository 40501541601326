import { useQuery } from '@apollo/client'

import { Amount, Box, Icons, Text } from '../../components'
import { queries } from '../../graphql'

function formatAMPM(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours ?? 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes}${ampm}`
  return strTime
}

function formatDate(date) {
  const month = date.getMonth() + 1 // months are 0-based in JS
  const day = date.getDate()
  const year = date.getFullYear()
  const formattedDate = `${month}/${day}/${year} at ${formatAMPM(date)}`
  return formattedDate
}

function DonorDetails({ data }) {
  const { data: { getUser: donor } = {}, donorLoading } = useQuery(queries.user.getUser, {
    variables: { userId: parseFloat(data.userId) },
  })

  let donorName = donor ? donor.name : ''

  if ('anonymous' in data && data.anonymous) {
    donorName = 'Anonymous'
  }

  return (
    <Box
      sx={{
        margin: '0px 10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text.Caption sx={{ fontSize: '0.85rem' }}>
        <span style={{ fontWeight: '550' }}>
          <Amount value={data.amount} />
        </span>{' '}
        from <span style={{ fontWeight: '550', textDecoration: 'underline' }}>{donorName}</span>
      </Text.Caption>
      <Text.Caption sx={{ fontSize: '0.85rem', color: 'grey' }}>
        {formatDate(new Date(data.sentAt ?? data.createdAt))}
      </Text.Caption>
    </Box>
  )
}

function GrantSummaryDetails({ data, dataSource }) {
  return (
    <Box
      sx={{
        margin: '0px 10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text.Caption sx={{ fontSize: '0.85rem' }}>
        <span style={{ fontWeight: '550' }}>{data.amount}</span>
      </Text.Caption>
      <Text.Caption sx={{ fontSize: '0.85rem', color: 'grey' }}>
        {formatDate(new Date(dataSource === 'disbursements' ? data.sentAt ?? data.createdAt : data.createdAt))}
      </Text.Caption>
    </Box>
  )
}

function RecurrentDonorDetails({ data }) {
  const { data: { getUser: donor } = {}, donorLoading } = useQuery(queries.user.getUser, {
    variables: { userId: parseFloat(data.userId) },
  })

  let donorName = donor ? donor.name : ''

  if ('anonymous' in data && data.anonymous) {
    donorName = 'Anonymous'
  }

  return (
    <Box
      sx={{
        margin: '0px 10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text.Caption sx={{ fontSize: '0.85rem' }}>
        <span style={{ fontWeight: '550' }}>
          <Amount value={data.amount} />
        </span>{' '}
        from <span style={{ fontWeight: '550', textDecoration: 'underline' }}>{donorName}</span>
      </Text.Caption>
      <Text.Caption sx={{ fontSize: '0.85rem', color: 'grey' }}>
        <span style={{ color: 'black' }}>next</span>: {formatDate(new Date(data.nextScheduledGrant))}
      </Text.Caption>
    </Box>
  )
}

function getDetails(type, data, dataSource) {
  switch (type) {
    case 'GrantRequest':
      return <DonorDetails data={data} />
    case 'GrantSummaryPaymentRecord':
      return <GrantSummaryDetails data={data} dataSource={dataSource} />
    case 'RecurrentGrantRequest':
      return <RecurrentDonorDetails data={data} />
    default:
      return <DonorDetails data={data} />
  }
}

function Row({ data, dataSource }) {
  const { __typename: type } = data
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        padding: '10px',
        justifyContent: 'flex-start',
        borderBottom: '1px solid lightgrey',
        transition: 'transform 0.15s ease-in-out',
        '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '35px' }}>
        <Icons.ActivityItem row={data} />
      </Box>
      <Box sx={{ width: '70%' }}>{getDetails(type, data, dataSource)}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icons.State state={data.state} fontSize="0.85rem" />
      </Box>
    </Box>
  )
}

function sortRows(r1, r2) {
  const { __typename: type } = r1
  if (type === 'GrantSummaryPaymentRecord') {
    return r1.createdAt < r2.createdAt
  }
  if (!r1.sentAt || !r2.sentAt) {
    return r1.createdAt < r2.createdAt
  }
  return r1.sentAt < r2.sentAt
}

export function MiniGrid({ rows = [], dataSource }) {
  const emptyGrid = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: '2rem',
      }}
    >
      <Icons.Database style={{ opacity: '0.4' }} />
      <Text.Body sx={{ marginTop: '10px' }}>No data</Text.Body>
    </Box>
  )

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '350px',
      }}
    >
      {rows.length
        ? rows
            .toSorted(sortRows)
            .slice(0, 5)
            .map((row) => <Row data={row} dataSource={dataSource} />)
        : emptyGrid()}
    </Box>
  )
}
