import { forwardRef, useImperativeHandle } from 'react'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Acknowledge, BlockQuote, Button, Dialog, Icons, Row, Stack, Text } from '../../../components'
import { mutations, queries } from '../../../graphql'
import { calculateGiftAmount } from '../gift-details-math'
import { toAmountString } from '../../../utils'

export const PaymentUI = forwardRef(({ me, defaultFund, user }, ref) => {
  useImperativeHandle(ref, () => ({}))
  const name = me?.name ?? `${user.firstName} ${user.lastName}` ?? 'Guest'

  return (
    <Stack spacing={2}>
      <Text.Body>E-Transfers can be performed from your online banking platform using the following details:</Text.Body>
      <BlockQuote>
        <Text.Bold>Recipient</Text.Bold>
        <Text.Body>
          GiveWise Canada <br />
          donations@givewise.ca
        </Text.Body>
        <Text.Bold>Notes</Text.Bold>
        <Text.Body>
          {/** unsure as to what Giving Fund is */}
          Reference #{user.funds[0].number} <br />
          Donor Name: {name}
        </Text.Body>
      </BlockQuote>
      <Text.Body>
        <strong>*IMPORTANT:</strong> In the notes section, you must remember to enter your first and last name and your
        GiveWise Giving Fund Number, so we can properly receive your funds into the correct Giving Fund.
      </Text.Body>
    </Stack>
  )
})

export async function getPaymentValues({ values }) {
  return values
}

export async function createContributions({ client, fundId, giftDetails, userId }) {
  const amounts = calculateGiftAmount(giftDetails)
  const contribution = await client
    .mutate({
      mutation: mutations.contributions.createGuestContributionRecord,
      variables: {
        userId,
        fundId,
        amount: amounts.total,
        paymentType: 3,
        description: 'e-Transfer',
        taxReceipted: true,
        charityId: giftDetails.charityId,
        usedGiveGenius: true,
      },
    })
    .then(({ data }) => data.createGuestContributionRecord)

  return [contribution]
}

export function SuccessUI({ me, user, grantTotal, charityId, charityName }) {
  const name = me?.name ?? `${user.firstName} ${user.lastName}` ?? 'Guest'

  const navigate = useNavigate()

  const handleSignUpClick = () => {
    navigate('/sign-up')
  }

  return (
    <Dialog open hideCloseButton>
      <Row alignItems="center" spacing={4}>
        <Icons.Success sx={{ fontSize: '3rem' }} color="success" />
        <DialogTitle
          sx={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100%' }}
        >
          <Typography variant="h5">One More Step! Action Required to Complete Donation.</Typography>
        </DialogTitle>
      </Row>

      <DialogContent>
        <Text.Body sx={{ mb: 2 }}>
          Dear <strong>{name}</strong>,
        </Text.Body>
        <Text.Body sx={{ mb: 1 }}>
          Thank you for your donation to{' '}
          <strong>
            {charityName} ({charityId})
          </strong>
          . Your generosity is making a meaningful impact, and we’re so grateful for your support.
        </Text.Body>
        <Text.Body sx={{ mb: 3 }}>
          You will now need to send an e-Transfer in the amount of <strong>${grantTotal.toFixed(2)}</strong> to GiveWise
          using your online banking platform in order to complete this transaction.
        </Text.Body>
        <Text.H6 sx={{ mb: 2 }}>
          <strong>Here are the instructions:</strong>
        </Text.H6>
        <Text.Body sx={{ mb: 1 }}>
          <strong>Amount To Send:</strong> ${grantTotal.toFixed(2)}
        </Text.Body>
        <Text.Body>
          <strong>Recipient:</strong>
        </Text.Body>
        <Text.Body>GiveWise Canada</Text.Body>
        <Text.Body sx={{ mb: 1 }}>donations@givewise.ca</Text.Body>
        <Text.Body>
          <strong>Note or Memo:</strong>
        </Text.Body>
        <Text.Body>
          Reference #<strong>{user.funds[0].number}</strong>
        </Text.Body>
        <Text.Body sx={{ mb: 3 }}>Donor Name: {name}</Text.Body>
        <Text.Body sx={{ mb: 3 }}>
          Once the e-Transfer has been received and posted, the gift will be sent and you will receive an email
          notification. This can take up to 3 business days.
        </Text.Body>
        <Text.H6 sx={{ mb: 1 }}>
          <strong>You’re Almost There!</strong>
        </Text.H6>
        <Text.Body>Add a password and create a Giving Fund so that you can:</Text.Body>
        <ul>
          <li>
            <Text.Body>Access your tax receipt.</Text.Body>
          </li>
          <li>
            <Text.Body>Set up recurring giving effortlessly.</Text.Body>
          </li>
          <li>
            <Text.Body>Streamline all your donations in one convenient place.</Text.Body>
          </li>
          <li>
            <Text.Body>Simplify tax time with a single tax receipt for all your giving.</Text.Body>
          </li>
        </ul>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSignUpClick}>
          Add Password
        </Button>
      </DialogActions>
    </Dialog>
  )
}
