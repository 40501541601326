import { gql } from '@apollo/client'

export const myCreditCards = gql`
  query MyCreditCards {
    me {
      id
      creditCards {
        id
        details
        multiUse
        status
        default
        canDelete
      }
    }
  }
`
export const myLinkedBanks = gql`
  query MyLinkedBanks {
    me {
      id
      linkedBanks {
        id
        details
        canDelete
      }
    }
  }
`

export const iq11Url = gql`
  query Iq11Url($args: GetIq11IframeUrlInput!) {
    iq11Url(args: $args) {
      Success
      ErrorMessage
      EmbedURL
      IframeKey
    }
  }
`

export const getZumrailsToken = gql`
  query getZumrailsToken {
    getZumrailsToken
  }
`

export const userHasBankAccount = gql`
  query userHasBankAccount {
    userHasBankAccount
  }
`

export const getZumRailsUser = gql`
  query getZumRailsUser {
    getZumRailsUser
  }
`

export const linkedZumRailsBanks = gql`
  query linkedZumRailsBanks {
    linkedZumRailsBanks {
      Id
      Institution
      InstitutionNumber
      TransitNumber
      AccountNumber
      AccountType
      Name
      FirstName
      LastName
      CreatedAt
      AggregationStatus
      LastTimeRefreshed
      AggregationFailedReason
      AggregationBalance
      HasMinimumInformationForEft
      FundingSourceStatus
      Country
      NameMatchResult
      NameMatchScore
      UpdatedAt
      AggregationRequestId
      PaymentMethodId
      zumUserId
    }
  }
`
