import { useState, useRef, useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useForm, Controller, useController } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DataGrid, gridRowCountSelector } from '@mui/x-data-grid'

import {
  Acknowledge,
  Button,
  Box,
  Container,
  ContentBox,
  Fields,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  MenuItem,
  Typography,
  Row,
  Select,
  Stack,
  Switch,
  Text,
  useAlert,
  RadioGroup,
  CardBox,
  Icons,
} from '../../components'
import { validations } from '../../components/form'
import { mutations, queries } from '../../graphql'
import { MiniGrid } from './mini-grid'

function smallActivityFeedHeading(name) {
  switch (name) {
    case 'gifts':
      return 'Gift Activity'
    case 'disbursements':
      return 'Disbursement Activity'
    case 'recurring-gifts':
      return 'Recurring Gifts'
    default:
      return name
  }
}
function getTypeName(value) {
  switch (value) {
    case 'GrantRequest':
      return 'Gifts'
    case 'DafTransfer':
      return 'Shared Funds'
    default:
      return value
  }
}
function getDataFromSourceName(name, charity) {
  switch (name) {
    case 'gifts': {
      const sortedGiftActivity = [...charity.grantRequests]
      sortedGiftActivity.sort((a, b) => {
        const cmpDate = new Date(b.createdAt) - new Date(a.createdAt)
        if (cmpDate === 0) {
          return b.id - a.id
        }
        return cmpDate
      })

      return sortedGiftActivity
    }
    case 'disbursements': {
      const sortedDisbursementActivity = [...charity.grantSummaries]
      sortedDisbursementActivity.sort((a, b) => {
        const cmpDate = new Date(b.createdAt) - new Date(a.createdAt)
        if (cmpDate === 0) {
          return b.id - a.id
        }
        return cmpDate
      })

      return sortedDisbursementActivity
    }
    case 'recurring-gifts':
      return charity.recurringGrantRequests.filter((rgr) => rgr.state === 'active')
    default:
      return []
  }
}

function SmallActivityFeed({ charityId, dataSource, data = [] }) {
  return (
    <CardBox
      sx={{
        borderRadius: '15px',
        padding: '20px',
        margin: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '550px',
        width: '500px',
      }}
    >
      <Box sx={{ width: '100%', marginBottom: '10px' }}>
        <Text.H6 sx={{ fontWeight: '450' }}>{smallActivityFeedHeading(dataSource)}</Text.H6>
      </Box>
      <MiniGrid rows={data} dataSource={dataSource} />
      <Button to={`/charities/${charityId}/activity/${dataSource}`} sx={{ marginTop: '20px' }}>
        View More
      </Button>
    </CardBox>
  )
}

export function CharityPortalDashboard() {
  const { id: charityId } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { afterLogin } = state ?? {}
  const { data: { me } = {}, loading } = useQuery(queries.user.me)
  const [updateFirstLogin] = useMutation(mutations.user.updateFirstLogin, {
    refetchQueries: ['Me'],
  })
  const { data: { charity } = {}, charityLoading } = useQuery(queries.charities.myCharityActivity, {
    variables: { charityId: useParams().id },
  })
  const sources = ['gifts', 'disbursements', 'recurring-gifts']

  useEffect(() => {
    if (me) {
      const charityUser = me.charityUsers.find((cu) => cu.userId === me.id)
      if (!charityUser.firstLogin) {
        updateFirstLogin({
          variables: {
            data: true,
          },
        })
        navigate(`/charities/${charityId}/confirm`)
      }
    }
  })

  return (
    <Container sx={{ maxWidth: '78vw !important' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {charity
          ? sources.map((source) => (
              <SmallActivityFeed
                charityId={charityId}
                dataSource={source}
                data={getDataFromSourceName(source, charity)}
              />
            ))
          : ''}
      </Box>
    </Container>
  )
}
