import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { Skeleton, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import {
  Button,
  Checkbox,
  Fields,
  FormControlLabel,
  FormGroup,
  Icons,
  InputAdornment,
  MenuItem,
  Row,
  Stack,
  Text,
} from '..'
import { mutations, queries } from '../../graphql'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const StripeInput = forwardRef(({ component: Component, ...props }, ref) => (
  <>
    {/* this is a dummy element to hold the ref to get rid of mui input ref error */}
    <span ref={ref} aria-hidden="true" style={{ display: 'none' }} />
    <Component {...props} />
  </>
))

export const GuestGenerosityForm = forwardRef((p, ref) => (
  <Elements stripe={stripePromise}>
    <NewCard {...p} ref={ref} saveCardOption={false} />
  </Elements>
))

export const GuestForm = forwardRef((p, ref) => (
  <Elements stripe={stripePromise}>
    <GuestCard {...p} ref={ref} saveCardOption={false} />
  </Elements>
))

export const Select = forwardRef((p, ref) => (
  <Elements stripe={stripePromise}>
    <NewOrOld {...p} ref={ref} />
  </Elements>
))

const NewOrOld = forwardRef(({ register }, ref) => {
  const profile = useQuery(queries.user.me)
  const card = useQuery(queries.paymentMethods.myCreditCards)

  const loading = profile.loading || card.loading
  const savedCards = card.data?.me?.creditCards.filter((cc) => cc.status === 'active')
  const hasSavedCards = savedCards && savedCards.length

  const [isNewCard, setIsNewCard] = useState(false)

  const client = useApolloClient()
  const newCard = useRef()

  /* after loading, if we have no saved cards, then we're adding a new card  */
  useEffect(() => !loading && !hasSavedCards && setIsNewCard(true), [loading, hasSavedCards])

  useImperativeHandle(ref, () => ({
    isNewCard,
    postToStripeAndSavePaymentMethod: async ({ multiUse }) => {
      const firstName = profile?.firstName
      const lastName = profile?.lastName

      const { data: { initiateCreditCard: clientSecret } = {} } = await client.mutate({
        mutation: mutations.paymentMethods.initiateCreditCard,
      })

      const setupIntent = await newCard.current.postToStripe({
        clientSecret,
        multiUse,
        firstName,
        lastName,
      })

      const paymentMethodId = setupIntent.payment_method

      const { data } = await client.mutate({
        mutation: mutations.paymentMethods.finalizeCreditCard,
        variables: { data: { paymentMethodId, multiUse } },
        refetchQueries: [{ query: queries.paymentMethods.myCreditCards }],
      })

      return data.finalizeCreditCard.id
    },
  }))

  if (loading) return <Skeleton width="30rem" height="56px" />

  return isNewCard ? (
    <NewCard ref={newCard} setIsNewCard={hasSavedCards && setIsNewCard} register={register} />
  ) : (
    <SavedCards savedCards={savedCards} setIsNewCard={setIsNewCard} register={register} />
  )
})

function SavedCards({ savedCards, setIsNewCard, register }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [valueAs, _setValueAs] = useState(savedCards?.length ? savedCards[0].id : '')
  const setValueAs = (text) => {
    const v = parseInt(text, 10)
    _setValueAs(v)
    return v
  }
  const onChange = ({ target: { value } }) => setValueAs(value)

  return (
    <Row justifyContent="space-between" alignItems="flex-start">
      <Fields.Text
        id="outlined-select-currency"
        select
        label="Saved Card"
        sx={{ maxWidth: '30rem' }}
        {...register('paymentMethodId', { setValueAs, onChange })}
        value={valueAs}
        defaultValue={valueAs}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icons.CreditCard />
            </InputAdornment>
          ),
        }}
      >
        {savedCards?.map(
          ({
            id,
            /* eslint-disable camelcase */
            details: { card: { brand = 'Card', last4 = '????', exp_month = '??', exp_year = '??' } = {} } = {},
          }) => (
            <MenuItem key={id} value={id}>
              <span style={{ textTransform: 'uppercase' }}>{brand}</span>&nbsp;ending in {last4} exp. {exp_month}/
              {exp_year}
            </MenuItem>
          )
        )}
      </Fields.Text>

      <Stack alignItems={`flex-${isMobile ? 'start' : 'end'}`}>
        <Stack width="auto" spacing={1} alignItems="center">
          <Button size="small" color="info" fullWidth onClick={() => setIsNewCard(true)}>
            Use a new credit card
          </Button>
          <Button size="small" fullWidth to="/profile#manage-credit-cards">
            Manage Credit Cards
          </Button>
        </Stack>
      </Stack>
    </Row>
  )
}

const NewCard = forwardRef(({ setIsNewCard, register, saveCardOption = true }, ref) => {
  const elements = useElements()
  const stripe = useStripe()

  useImperativeHandle(ref, () => ({
    postToStripe: async ({ clientSecret, firstName, lastName }) => {
      const name = [firstName, lastName].filter(Boolean).join(' ')

      const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement('cardNumber'),
          billing_details: { name },
        },
      })

      if (error) throw error

      elements.getElement(CardNumberElement)?.clear()
      elements.getElement(CardExpiryElement)?.clear()
      elements.getElement(CardCvcElement)?.clear()

      return setupIntent
    },
  }))

  return (
    <Stack>
      <Fields.Text
        label="Card Number"
        name="ccnumber"
        variant="outlined"
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardNumberElement,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Icons.CreditCard />
            </InputAdornment>
          ),
        }}
      />
      <Row>
        <Fields.Text
          label="Expiration Date"
          name="ccexp"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
            },
          }}
        />
        <Fields.Text
          label="CVC"
          name="cccvc"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
            },
          }}
        />
      </Row>
      <Row justifyContent="space-between">
        {saveCardOption && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Save this card to use again later"
              {...register('multiUse')}
            />
          </FormGroup>
        )}
        {setIsNewCard && (
          <Button variant="text" onClick={() => setIsNewCard(false)}>
            Use a Saved Credit Card
          </Button>
        )}
      </Row>
    </Stack>
  )
})

const GuestCard = forwardRef((_, ref) => {
  const elements = useElements()
  const stripe = useStripe()
  const client = useApolloClient()

  useImperativeHandle(ref, () => ({
    postToStripe: async ({ firstName, lastName, stepperState }) => {
      const name = [firstName, lastName].filter(Boolean).join(' ')

      const clientSecretRes = await client.mutate({
        mutation: mutations.guestPaymentMethods.initiateGuestCreditCard,
        variables: {
          userId: stepperState?.user?.id,
        },
      })
      const clientSecret = clientSecretRes?.data?.initiateGuestCreditCard

      const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement('cardNumber'),
          billing_details: { name },
        },
      })

      if (error) throw error

      const paymentMethodString = setupIntent?.payment_method
      const { data } = await client.mutate({
        mutation: mutations.guestPaymentMethods.finalizeGuestCreditCard,
        variables: {
          userId: stepperState?.user?.id,
          data: { paymentMethodId: paymentMethodString, multiUse: false },
        },
      })
      const paymentMethodId = data?.finalizeGuestCreditCard?.id
      return paymentMethodId
    },
  }))

  return (
    <Stack>
      <Fields.Text
        label="Card Number"
        name="ccnumber"
        variant="outlined"
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardNumberElement,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Icons.CreditCard />
            </InputAdornment>
          ),
        }}
      />
      <Row>
        <Fields.Text
          label="Expiration Date"
          name="ccexp"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
            },
          }}
        />
        <Fields.Text
          label="CVC"
          name="cccvc"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
            },
          }}
        />
      </Row>
    </Stack>
  )
})

export function FeesNotice() {
  return (
    <Text.Body>
      <strong>Note: </strong>
      Credit card transactions are the quickest way to contribute but will incur a 2.20% fee from our credit card
      processor + $0.30 one time fee. Some credit cards are subject to higher fees. For more information, email{' '}
      <a href="mailto: support@givewise.ca">support@givewise.ca</a>.
    </Text.Body>
  )
}
