import { Button as MuiButton } from '@mui/material'
import { Check, CopyAll } from '@mui/icons-material'
import { forwardRef, useState } from 'react'

import palette from '../../palette.json'

export const CopyButton = forwardRef(({ text, unstyled, sx, children, ...props }, ref) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 1000)
    })
  }
  return (
    <MuiButton
      ref={ref}
      onClick={handleCopy}
      variant={unstyled ? 'text' : 'contained'}
      size="small"
      sx={{
        ...sx,
        ...(unstyled && {
          color: palette.black,
          backgroundColor: 'transparent',
          px: 0,
          textTransform: 'initial',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        }),
      }}
      {...props}
    >
      {copied ? (
        <>
          <Check sx={{ marginRight: 0.5 }} />
          Copied
        </>
      ) : (
        <>
          <CopyAll sx={{ marginRight: 0.5 }} />
          {children || 'Copy Code'}
        </>
      )}
    </MuiButton>
  )
})
