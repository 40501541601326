import { ApolloProvider, useQuery } from '@apollo/client'
import { Suspense, lazy, useEffect } from 'react'
import { Navigate, Outlet, matchPath, useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom'

import { isInDevOrForImpersonatorsInProd } from './pages/gift/showUnfinishedFeatures'
import {
  CharityActivity,
  CharityPortalDirectDeposit,
  CharityPortalInviteUsers,
  CharityPortalProfile,
  CharityPortalProjects,
  Disbursements,
  Gifts,
  GiveGeniusResources,
  RecurringGifts,
} from './pages/charity-portal'
import { LocalStorage, SHARE_FUND_CODE } from './components/local-storage/localStorage'
import {
  Charity as CharityC,
  ConfirmProvider,
  Donor,
  ErrorBoundary,
  GlobalAlertProvider,
  Header,
  LinearProgress,
  Navbar,
  NoticeHeader,
} from './components'
import { client as clientConfig, queries } from './graphql'
import { useAuth } from './hooks'
import {
  Charity,
  CharityDetailsConfirm,
  CharityLanding,
  CharityPaylink,
  CharityPortalDashboard,
  ClaimCharity,
  ClaimCharityByUserId,
  ConfirmAddedEmail,
  ConfirmEmail,
  Documents,
  ForgotPassword,
  FundList,
  GenerosityWorks,
  Login,
  Logout,
  MyGivingFund,
  NoMatch,
  Profile,
  ResetPassword,
  SignUp,
  SignUpSuccess,
  Successor,
} from './pages'
import {
  BankTransfer,
  Cheque,
  Contribute,
  CreditCard,
  DAF,
  ETransfer,
  Fund,
  FundActivity,
  GivingWallet,
  InvestmentAccount,
  ReceiveADafTransfer,
  Recurrent,
  RedirectToDefaultFund,
  Securities,
  ShareFundsWithGenerosityFund,
  ShareFundsWithOthers,
  PayButton,
} from './pages/funds'
import { BankTransferZum } from './pages/funds/contribute'
import { Charities, SearchByLocation, SearchByText } from './pages/charities'
import './App.css'
import { GiftToCharity } from './pages/gift/gift-to-charity'

const Management = lazy(() => import('./management'))

function ScrollToTop({ pathname }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

function MyCharity() {
  const navigate = useNavigate()
  const { data: { myCharity } = {}, charityLoading } = useQuery(queries.charities.myCharity)

  if (myCharity) {
    return <Navigate to={`${myCharity.id}`} />
  }
  return <LinearProgress />
}

function CharityConfirm() {
  const navigate = useNavigate()
  const { data: { myCharity } = {}, charityLoading } = useQuery(queries.charities.myCharity)

  if (myCharity) {
    return <Navigate to={`/charities/${myCharity.id}/confirm`} />
  }
  return <LinearProgress />
}

function App() {
  const routes = [
    {
      element: <Private />,
      children: [
        { index: true, element: <Navigate to="/funds" /> },
        {
          path: '/funds',
          element: <Outlet />,
          children: [
            { index: true, element: <FundList /> },
            {
              path: 'default/share-funds-with-generosity-fund/:generosityFundId',
              exact: true,
              element: (
                <RedirectToDefaultFund
                  using={({ generosityFundId }) => `share-funds-with-generosity-fund/${generosityFundId}`}
                />
              ),
            },
            {
              path: 'default/grant/:charityId',
              exact: true,
              element: <RedirectToDefaultFund using={({ charityId }) => `grant/${charityId}`} />,
            },
            {
              path: ':fundId',
              element: <Fund />,
              children: [
                { index: true, element: <MyGivingFund /> },
                { path: 'share-funds-with-others', exact: true, element: <ShareFundsWithOthers /> },
                {
                  path: 'share-funds-with-generosity-fund/:generosityFundId',
                  exact: true,
                  element: <ShareFundsWithGenerosityFund />,
                },
                {
                  path: 'grant',
                  element: <Charities />,
                  children: [
                    { index: true, element: <Navigate to="search-by-text" /> },
                    { path: 'search-by-text', element: <SearchByText /> },
                    { path: 'search-by-location', element: <SearchByLocation /> },
                  ],
                },
                { path: 'grant/:charityId', element: <CharityLanding /> },
                { path: 'grant/:charityId/give', element: <Charity /> },
                { path: 'documents', exact: true, element: <Documents /> },
                { path: 'fund-stats', exact: true, element: <Navigate to=".." /> },
                {
                  path: 'activity',
                  element: <FundActivity />,
                  children: [
                    { index: true, element: <Navigate to="giving-wallet" /> },
                    { path: 'giving-wallet', element: <GivingWallet /> },
                    { path: 'investment-account', element: <InvestmentAccount /> },
                    {
                      path: 'recurring',
                      element: <Recurrent />,
                    },
                  ],
                },
                {
                  path: 'contribute',
                  element: <Contribute />,
                  children: [
                    { index: true, element: <Navigate to="credit-card" /> },
                    { path: 'credit-card', element: <CreditCard /> },
                    { path: 'e-transfer', element: <ETransfer /> },
                    { path: 'cheque', element: <Cheque /> },
                    { path: 'daf-transfer', element: <DAF /> },
                    { path: 'bank-transfer', element: <BankTransferZum /> },
                    { path: 'securities', element: <Securities /> },
                    { path: 'apple-pay', element: <PayButton /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: '/receive-a-daf-transfer',
          element: <Outlet />,
          children: [
            { index: true, exact: true, element: <NoMatch /> },
            {
              path: ':id',
              element: <Outlet />,
              children: [
                { index: true, exact: true, element: <NoMatch /> },
                { path: ':receiveToken', element: <ReceiveADafTransfer /> },
              ],
            },
          ],
        },
        { path: '/successor', exact: true, element: <Successor /> },
        { path: '/profile', exact: true, element: <Profile /> },
      ],
    },
    {
      element: <CharityPortal />,
      children: [
        { index: true, element: <Navigate to="/charities" /> },
        {
          path: '/charities',
          element: <Outlet />,
          children: [
            { index: true, element: <MyCharity /> },
            {
              path: 'confirm',
              element: <CharityConfirm />,
            },
            {
              path: ':id',
              element: <Outlet />,
              children: [
                { index: true, element: <Navigate to="dashboard" /> },
                { path: 'confirm', element: <CharityDetailsConfirm /> },
                { path: 'dashboard', element: <CharityPortalDashboard /> },
                {
                  path: 'activity',
                  element: <CharityActivity />,
                  children: [
                    { index: true, element: <Navigate to="gifts" /> },
                    { path: 'gifts', element: <Gifts /> },
                    { path: 'disbursements', element: <Disbursements /> },
                    { path: 'recurring-gifts', element: <RecurringGifts /> },
                  ],
                },
                { path: 'direct-deposit', element: <CharityPortalDirectDeposit /> },
                { path: 'designations', element: <CharityPortalProjects /> },
                { path: 'charity-profile', element: <CharityPortalProfile /> },
                { path: 'my-profile', element: <Profile /> },
                { path: 'invite-users', element: <CharityPortalInviteUsers /> },
                { path: 'givegenius', element: <GiveGeniusResources /> },
                // { path: 'claim/:token', element: <ClaimCharity /> },
              ],
            },
          ],
        },
        { path: '/charities/:id/confirm', exact: true, element: <CharityDetailsConfirm /> },
        // { path: '/charities/:id/gifts', exact: true, element: <Gifts /> },
      ],
    },
    {
      element: <Public />,
      children: [
        { path: '/login', exact: true, element: <Login /> },
        { path: '/logout', exact: true, element: <Logout /> },
        {
          path: '/sign-up',
          element: <Outlet />,
          children: [
            { index: true, element: <Navigate to="signup" /> },
            { path: 'signup', element: <SignUp /> },
            { path: 'success', element: <SignUpSuccess /> },
          ],
        },
        { path: '/signup', exact: true, element: <Navigate to="/sign-up" /> },
        { path: '/confirm/:userId/:confirmationToken', element: <ConfirmEmail /> },
        { path: '/confirm-new-email/:confirmationToken', element: <ConfirmAddedEmail /> },
        { path: '/forgot-password', exact: true, element: <ForgotPassword /> },
        { path: '/reset-password/:token', element: <ResetPassword /> },
        { path: '/gift/charity/:charityId', element: <GiftToCharity /> },
        { path: '/charities/:id/paylink/:token', exact: true, element: <CharityPaylink /> },
        { path: '/charities/:id/claim/:token', element: <ClaimCharity /> },
        { path: '/charities/:id/claim/:userId/:token', element: <ClaimCharityByUserId /> },
        // { path: '/charities/:id/claim', exact: true, element: <ClaimCharity /> },
        { path: '/give/:fundId', exact: true, element: <GenerosityWorks /> },
        { path: '*', element: <NoMatch /> },
      ],
    },
    {
      element: <PrivateAdmin />,
      children: [
        {
          path: '/management/*',
          element: (
            <ErrorBoundary>
              <Suspense fallback={<LinearProgress />}>
                <Management />
              </Suspense>
            </ErrorBoundary>
          ),
        },
        // {
        //   path: '/experimental/zumrails',
        //   element: (
        //     <ErrorBoundary>
        //       <Suspense fallback={<LinearProgress />}>
        //         <br />
        //         <BankTransferZum />
        //       </Suspense>
        //     </ErrorBoundary>
        //   ),
        // },
      ],
    },
  ]

  const body = useRoutes(routes)
  const { pathname } = useLocation()
  return (
    <ApolloProvider client={clientConfig}>
      <GlobalAlertProvider>
        <LocalStorage>
          <ConfirmProvider>
            <ScrollToTop pathname={pathname} />
            <Header />
            {body}
          </ConfirmProvider>
        </LocalStorage>
      </GlobalAlertProvider>
    </ApolloProvider>
  )
}

export default App

function Public() {
  return (
    <Main>
      <Outlet />
    </Main>
  )
}

function Private() {
  const { data: { me } = {}, loading } = useQuery(queries.user.me)
  const { profile: { address } = {} } = me ?? {}
  const navigate = useNavigate()
  const [{ isLoggedIn }] = useAuth()
  const { pathname, state } = useLocation()
  const routeParams = useParams()
  const { afterLogin } = state ?? {}

  const { fundId } = useParams()
  const { loadingMyFundActivity, data: { me: { fund: { dafTransfers = [] } = {} } = {} } = {} } = useQuery(
    queries.funds.myFundActivity,
    {
      variables: { fundId: Number(fundId) },
    }
  )

  const pendingShareFunds = dafTransfers.filter((dt) => {
    const isStateInitiated = dt.state && dt.state === 'initiated'
    const isReceivingFund = dt.receivingFundId && dt.receivingFundId === fundId
    const isReceivingUser = dt.receivingUserId && dt.receivingUserId === me.id
    const isSentTo = me && dt.sentTo && dt.sentTo === me.email
    return isStateInitiated && (isReceivingFund || isReceivingUser || isSentTo)
  })

  if (pendingShareFunds.length === 0) {
    localStorage.removeItem(SHARE_FUND_CODE)
  }

  pendingShareFunds.forEach((sf) => {
    const expireTime = Date.now() + 1000 * 3600 * 24 * 3 // 3 days
    const expires = new Date()
    expires.setTime(expireTime)
    const expireString = expires.toUTCString()

    localStorage.setItem(
      SHARE_FUND_CODE,
      JSON.stringify({
        id: sf.id,
        code: sf.receiveToken,
        expires: expireString,
      })
    )
  })

  const isCharityPortalRoute = () =>
    pathname.indexOf('/charities') > -1 && (pathname.indexOf('/paylink/') < 0 || pathname.indexOf('/claim/'))

  useEffect(() => {
    if (!isLoggedIn) navigate('/login', { state: { afterLogin: afterLogin ?? pathname } })
    const userType = me?.profile?.userType

    if (pathname === '/' && userType === 'charity') {
      navigate('/charities')
      return
    }

    if (userType === 'charity') {
      // navigate(`/charities/${me?.charityUsers[0].charityId}`)
      navigate(`/404`)
    }

    if (userType !== 'charity' && isCharityPortalRoute()) {
      navigate('/404') // Only charity portal users should have access to it
    } else if (
      isCharityPortalRoute() &&
      'id' in routeParams &&
      !me?.charityUsers?.find((cu) => cu.charityId === routeParams.id)
    ) {
      navigate('/404')
    }

    if (userType && userType !== 'charity' && !address) {
      console.log('redirecting to profile')
      navigate('/profile', { state: { afterLogin: afterLogin ?? pathname } })
    }
  }, [address, pathname, isLoggedIn, me, navigate, afterLogin])

  // Detect Daf transfer token and store to local storage.
  useEffect(() => {
    const dafURLPattern = /.*\/receive-a-daf-transfer\/([0-9]+)\/(.+)/
    const giftCodeUrl = matchPath('/receive-a-daf-transfer/:id/:token', pathname) ? pathname : ''
    const match = giftCodeUrl?.match(dafURLPattern)
    if (!match) return // Guard clause if not valid URL

    // Get expiration Date
    const expireTime = Date.now() + 1000 * 3600 * 24 * 3 // 3 days
    const expires = new Date()
    expires.setTime(expireTime)
    const expireString = expires.toUTCString()

    localStorage.setItem(
      SHARE_FUND_CODE,
      JSON.stringify({
        id: match[1],
        code: match[2],
        expires: expireString,
      })
    )
  }, [isLoggedIn, pathname, navigate, afterLogin])

  if (loading) return <LinearProgress />
  return (
    <>
      <Navbar />
      <NoticeHeader />
      <Donor.Header />
      <Main>
        <Outlet />
      </Main>
    </>
  )
}

function CharityPortal() {
  const navigate = useNavigate()
  const [{ isLoggedIn }] = useAuth()
  const routeParams = useParams()
  const { data: { me } = {}, loading } = useQuery(queries.user.me)

  useEffect(() => {
    const isCharityUser = me?.profile?.userType === 'charity'

    if (!isLoggedIn) navigate('/login')
    if (loading) return
    if (!isCharityUser) {
      console.log('not a charity user')
      navigate('/404')
    }
    if ('id' in routeParams && !me?.charityUsers?.find((cu) => cu.charityId === routeParams.id)) {
      console.log('no charity users found')
      navigate('/404')
    }
  }, [isLoggedIn, navigate, me, loading])

  if (loading) {
    return ''
  }

  return (
    <>
      <Navbar />
      <NoticeHeader />
      <CharityC.Header />
      <Main>
        <Outlet />
      </Main>
    </>
  )
}

function PrivateAdmin() {
  const { data: { me } = {}, loading } = useQuery(queries.user.me)
  const navigate = useNavigate()
  const [{ isLoggedIn }, isAdmin] = useAuth()
  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
    if (!isAdmin || me?.profile?.userType === 'charity') navigate('/404')
  }, [isLoggedIn, isAdmin, navigate])

  if (loading) return <LinearProgress />

  return (
    <>
      <Navbar />
      <Outlet />
    </>
  )
}

function Main({ children }) {
  return (
    <main id="main-content" className="main">
      {children}
    </main>
  )
}
