import { Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Container, ContentBox, CopyButton, Row, Stack, Text } from '../../../components'
import WhiteOwlIcon from './WhiteOwlIcon.png'
import NavyOwlIcon from './NavyOwlIcon.png'

function DonateButton(props) {
  const { codeValues, value, charityId } = props
  const buttonIcons = [WhiteOwlIcon, NavyOwlIcon, WhiteOwlIcon]
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      style={{
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        margin: '30px 0',
      }}
    >
      <a
        href={`https://fund.givewise.ca/gift/charity/${charityId}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          border: 'none',
          outline: 'none',
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          backgroundColor: codeValues.backgroundColor[value],
          padding: '15px 25px',
          borderRadius: '8px',
          boxShadow: isHovered ? '0px 5px 10px rgba(0, 0, 0, 0.3)' : '0px 3px 6px rgba(0, 0, 0, 0.2)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          gap: '7px',
          width: 'fit-content',
          transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Owl icon and Donate text */}
        <img
          src={buttonIcons[value]}
          alt="Donate through GiveWise"
          style={{
            width: '27px',
            height: '27px',
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        />
        <span
          style={{
            fontFamily: 'Montserrat, sans-serif',
            color: codeValues.textColor[value],
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
            lineHeight: '1',
            marginLeft: '5px',
          }}
        >
          Give
        </span>
      </a>
    </div>
  )
}

function CodeBlockPanel(props) {
  const { children, value, index, charityId, ...other } = props

  /**
   * Values for each button type
   * 0: navy button
   * 1: yellow button
   * 2: grey button
   */
  const codeValues = {
    cssPath: ['./GiveButtonNavy.css', './GiveButtonYellow.css', './GiveButtonGrey.css'],
    iconPath: ['../Assets/owl-icon-white.png', '../Assets/owl-icon.png', '../Assets/owl-icon-white.png'],
    componentName: ['GiveButtonNavy', 'GiveButtonYellow', 'GiveButtonGrey'],
    backgroundColor: ['#293860', '#f3cb12', '#b0b0b0'],
    backgroundColorComment: [
      '/* Navy button background color */',
      '/* Yellow button background color */',
      '/* Grey button background color */',
    ],
    textColor: ['#ffffff', '#293860', '#ffffff'],
    textColorComment: [
      '/* Navy button text color */',
      '/* Yellow button text color */',
      '/* Grey button text color */',
    ],
  }

  const codeString = `import React from 'react';
import '${codeValues.cssPath[value]}'; // Ensure correct CSS file path
import OwlIcon from '${codeValues.iconPath[value]}'; // Ensure correct owl icon path

export default function ${codeValues.componentName[value]}() {
  return (
    <div className="donate-container">
      <a
        href="https://fund.givewise.ca/gift/charity/${charityId}" // Ensure correct charity ID
        className="donate-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* Owl icon and Donate text */}
        <img
          src={OwlIcon}
          alt="Donate through GiveWise"
          className="donate-icon"
        />
        <span className="donate-text">Give</span>
      </a>
    </div>
  );
};`

  const cssString = `/* Main container for the donate link */
.donate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  /* Link styling */
  .donate-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: ${codeValues.backgroundColor[value]}; ${codeValues.backgroundColorComment[value]}
    padding: 15px 25px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    gap: 5px;
    width: fit-content;
  }
  
  /* Hover effect */
  .donate-link:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Icon styling */
  .donate-icon {
    width: 27px;
    height: 27px;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Text styling */
  .donate-text {
    font-family: 'Montserrat', sans-serif;
    color: ${codeValues.textColor[value]}; ${codeValues.textColorComment[value]}
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }`

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
          {children}
          <DonateButton codeValues={codeValues} value={value} charityId={charityId} />
          <Text.H5 sx={{ marginTop: 3 }}>Component Code</Text.H5>
          <Text.CodeBlock showLineNumbers>{codeString}</Text.CodeBlock>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CopyButton text={codeString} />
          </Box>
          <Text.H5 sx={{ marginTop: 3 }}>CSS Parameters</Text.H5>
          <Text.CodeBlock language="css" showLineNumbers>
            {cssString}
          </Text.CodeBlock>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CopyButton text={cssString} />
          </Box>
        </Box>
      )}
    </div>
  )
}

export function GiveGeniusResources() {
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex)
  }

  const { id: charityId } = useParams()

  return (
    <Container maxWidth="lg">
      <Stack spacing={4}>
        <Row style={{ margin: 0 }} justifyContent="space-between">
          <Box>
            <Text.H1 sx={{ marginBottom: 1 }}>GiveGenius Resources</Text.H1>
            <Text.Body sx={{ marginBottom: 2 }}>
              GiveGenius is an enhanced payment solution that brings all donation methods together in a single,
              user-friendly platform, offering your donors more ways to give and providing your team with the clarity
              you need to grow. By making donations easier and more accessible, we help you focus on what truly matters:
              your mission.
            </Text.Body>
          </Box>
        </Row>
      </Stack>
      <ContentBox style={{ marginTop: 25 }} border>
        <Text.H2 sx={{ marginBottom: 3 }}>Personalized Fundraising Toolkit</Text.H2>

        <Text.H5 sx={{ marginBottom: 1 }}>GiveGenius URL</Text.H5>
        <Text.Body>
          This URL can be shared and will direct your donors to your GiveGenius Giving Page and guide them through
          seamless online donations right to your charity. This can be included as a hyperlink on your website or in a
          newsletter, and can also be used to create a QR code.
        </Text.Body>
        <Text.CodeBlock language="plaintext">{`https://fund.givewise.ca/gift/charity/${charityId}`}</Text.CodeBlock>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 4 }}>
          <CopyButton text={`https://fund.givewise.ca/gift/charity/${charityId}`} />
        </Box>

        <Text.H5 sx={{ marginBottom: 1 }}>GiveGenius GiveButton</Text.H5>
        <Text.Body sx={{ marginBottom: 1 }}>
          This donation button allows charities to accept secure online donations while offering donors an easy and
          seamless giving experience. It supports either one-time or recurring gifts, as well as multiple payment
          methods such as credit card, bank transfers, transfer from other DAF and more.
        </Text.Body>
        <Text.Body sx={{ marginBottom: 1 }}>
          Please copy the code to have your web developer/administrator embed on your website. Be sure to inform your
          developers that the code is written in React, and they may need to adapt it if your website uses a different
          technology stack.
        </Text.Body>
        {/* Tabs */}
        <Box>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="GiveGenius Tabs">
            <Tab label="Navy Button" />
            <Tab label="Yellow Button" />
            <Tab label="Grey Button" />
          </Tabs>

          {/* Tab Panels */}
          <CodeBlockPanel value={tabIndex} index={0} charityId={charityId} />
          <CodeBlockPanel value={tabIndex} index={1} charityId={charityId} />
          <CodeBlockPanel value={tabIndex} index={2} charityId={charityId} />
        </Box>
      </ContentBox>
    </Container>
  )
}
