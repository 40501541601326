import { forwardRef } from 'react'
import { Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

export const H1 = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="h1" {...props}>
    {children}
  </Typography>
))
export const H2 = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="h2" {...props}>
    {children}
  </Typography>
))
export const H3 = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="h3" {...props}>
    {children}
  </Typography>
))
export const H4 = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="h4" {...props}>
    {children}
  </Typography>
))
export const H5 = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="h5" {...props}>
    {children}
  </Typography>
))
export const H6 = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="h6" {...props}>
    {children}
  </Typography>
))
export const H7 = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="h7" {...props}>
    {children}
  </Typography>
))
export const Body = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="body1" {...props}>
    {children}
  </Typography>
))
export const AltBody = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="body2" {...props}>
    {children}
  </Typography>
))
export const Caption = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="caption" {...props}>
    {children}
  </Typography>
))
export const Subtitle = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="subtitle1" {...props}>
    {children}
  </Typography>
))
export const AltSubtitle = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="subtitle2" {...props}>
    {children}
  </Typography>
))
export const SR = forwardRef(({ children, ...props }, ref) => (
  <Typography component="span" ref={ref} {...props} sx={visuallyHidden}>
    {children}
  </Typography>
))

export const Bold = forwardRef(({ children, ...props }, ref) => (
  <Typography ref={ref} variant="body1" sx={{ fontWeight: 'bold' }} {...props}>
    {children}
  </Typography>
))

export const CodeBlock = forwardRef(
  ({ children, language = 'javascript', customStyle = { borderRadius: 8, fontSize: '14px' }, ...props }, ref) => (
    <SyntaxHighlighter ref={ref} language={language} customStyle={customStyle} {...props}>
      {children}
    </SyntaxHighlighter>
  )
)
