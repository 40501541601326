import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import invariant from 'invariant'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'

import {
  Button,
  Checkbox,
  Container,
  ContentBox,
  Fields,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icons,
  InputAdornment,
  InputLabel,
  MenuItem,
  Recurring,
  Row,
  Scheduled,
  Select,
  Stack,
  Text,
  Tooltip,
  longWait,
  useAlert,
  validations,
} from '../../components'
import { useLoginDialog } from '../../components/login/useLoginDialog'
import * as SecuritiesPayment from '../../components/payment/securities'
import { mutations, queries } from '../../graphql'
import { useAuth } from '../../hooks'
import palette from '../../palette.json'
import { userName } from '../../utils'
import { calculateTipAmount } from './gift-details-math'
import { PaymentMethodsMeta } from './payment'
import { GuestPaymentMethodsMeta } from './payment-guest/index'
import { PaymentMethodRadioList } from './PaymentMethodRadioList'
import { onlyShowInDev } from './showUnfinishedFeatures'

export function GiftAmountStep({ updateSteps, isLoggedIn, charity, me, defaultFund, stepperState, dispatchStep }) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    if (onlyShowInDev) console.log(`gift amount stepperState: ${JSON.stringify(stepperState, null, 2)}`)
  }, [stepperState])
  const navigate = useNavigate()

  const { register, handleSubmit, formState, getValues, setValue, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      paymentMethodType: stepperState?.payment?.paymentMethodType ?? 'credit-card',
      // Gift
      amount: stepperState?.giftDetails?.amount ?? '0',
      securities: stepperState?.giftDetails?.securities ?? SecuritiesPayment.defaultSecurities,
      addTip: stepperState?.giftDetails?.addTip ?? true,
      customTip: stepperState?.giftDetails?.customTip ?? '',
      isRecurring: stepperState?.giftDetails?.isRecurring ?? false,
      recurringInterval: stepperState?.giftDetails?.recurringInterval || 'monthly',
      purposeOfGrant: stepperState?.giftDetails?.purposeOfGrant ?? '',
      purposeOfGrantNote: stepperState?.giftDetails?.purposeOfGrantNote ?? '',
      processingDate: stepperState?.giftDetails?.processingDate ?? null,
      selfAttestation: stepperState?.giftDetails?.selfAttestation ?? false,
      charityId: charity.id,
    },
  })
  const { isSubmitting, errors = {} } = formState

  const [{ Alert, alertProps }, { setAlert }] = useAlert()

  const [, { logout }] = useAuth()
  const { loginDialog, startLogin } = useLoginDialog()

  const { profile } = me ?? {}
  const profileName = userName(profile)

  const [canGiveAsGuest, { data: { canGiveAsGuest: canUseEmail = true } = {} }] = useLazyQuery(
    queries.funds.canGiveAsGuest
  )
  const [getUserByEmail] = useLazyQuery(queries.user.getUserByEmail)

  const checkEmailUse = async () => {
    const email = getValues('email').toLowerCase()
    const guestResult = await canGiveAsGuest({ variables: { email } })
    const userFromEmail = await getUserByEmail({ variables: { email } })

    // Only confirmed users have the a confirmedAt timestamp.
    const isGuestOrUnconfirmed =
      guestResult?.data?.canGiveAsGuest || typeof userFromEmail?.data?.getUserByEmail?.confirmedAt !== 'string'

    return isGuestOrUnconfirmed
  }

  const paymentMethodApi = useRef()
  const [_, setPaymentMethodLoading] = useState(false)

  const isAddTipOpen = watch('addTip')
  // const watchProcessingDate = watch('processingDate')
  // const isScheduledGrant = !!watchProcessingDate

  /**
   * initial guest creation functions
   */
  const filledEmail = watch('email')

  const { data: existingGuest } = useQuery(queries.user.getUserByEmail, {
    variables: {
      email: filledEmail,
    },
    skip: !filledEmail, // Skip the query if email is not filled
  })

  const [guestSignUp] = useMutation(mutations.user.guestSignUp, {
    onCompleted: async () => {},
    onError: async (newErrors) => {
      setAlert({
        timeout: longWait,
        message: `There was an issue with your email. Please try again.`,
        error: newErrors.message,
        severity: 'error',
      })
    },
  })

  const getOrCreateGuest = async (payload) => {
    if (existingGuest) {
      // Guest status is 1, Unconfirmed is 2, User is 3
      if (existingGuest?.getUserByEmail?.status === 1 || existingGuest?.getUserByEmail?.status === 2) {
        return existingGuest?.getUserByEmail
        // eslint-disable-next-line no-else-return
      } else {
        // email belongs to a non-guest user -> should we prompt them to login or allow continue as guest? what if not that user
        // parseAndSetFormErrors('This email belongs to a non-guest user. Please login.', setError)
        // console.log('This email belongs to a non-guest user')
      }
    }
    const newGuest = await guestSignUp({
      variables: payload,
    })
    return newGuest.data?.guestSignUp
  }

  let purposeOfGrantNoteLabel = ''
  switch (getValues('purposeOfGrant')) {
    case 'Fundraising Support':
      purposeOfGrantNoteLabel = 'Name of fundraising project'
      break
    case 'In memory of':
      purposeOfGrantNoteLabel = 'Full Name'
      break
    case 'Other':
      purposeOfGrantNoteLabel = 'Please describe the reason for your gift'
      break
    case 'Thanks for the work you do!':
    default:
      purposeOfGrantNoteLabel = 'Additional note'
      break
  }

  function reorderArrayByReason(array, reasons) {
    const specificOrder = reasons.map((reason) => array.find((item) => item.reason === reason))
    const filteredArray = array.filter((item) => !reasons.includes(item.reason))
    return specificOrder.concat(filteredArray)
  }

  const purposeOfGiftDefaults = ['General Support']

  const purposeOfGiftOptions = () => {
    if (charity?.purposeOfGifts && charity.purposeOfGifts.length) {
      return reorderArrayByReason(charity.purposeOfGifts, purposeOfGiftDefaults)
        .filter((p) => p.active)
        .map((p) => (
          <MenuItem key={p.reason} value={`${p.reason}`}>
            {p.reason}
          </MenuItem>
        ))
    }
    return purposeOfGiftDefaults.map((p) => <MenuItem value={`${p.reason}`}>{p.reason}</MenuItem>)
  }

  const location = useLocation()

  const onSubmit = async ({
    email,
    paymentMethodType,
    amount,
    securities,
    addTip,
    customTip,
    purposeOfGrant,
    purposeOfGrantNote,
    recurringInterval,
    isRecurring,
    processingDate,
    selfAttestation,
    ...payment
  }) => {
    try {
      // const PaymentMethodMeta = PaymentMethodsMeta[paymentMethodType] ?? {}
      const PaymentMethodMeta = !isLoggedIn
        ? GuestPaymentMethodsMeta[paymentMethodType] ?? {}
        : PaymentMethodsMeta[paymentMethodType] ?? {}

      invariant(
        PaymentMethodMeta.getPaymentValues,
        `Payment Method type ${paymentMethodType} is missing getPaymentValues`
      )

      let user
      if (!isLoggedIn) {
        user = await getOrCreateGuest({
          data: {
            firstName: 'Guest',
            middleInitials: '',
            lastName: 'User',
            corporateName: '',
            email,
            phone: '',
            address: {
              lineOne: '',
              lineTwo: '',
              city: '',
              province: '',
              postalCode: '',
              country: 'Canada', // You can provide a default value for country if required
            },
            userType: 'guest',
            afterLogin: location.pathname,
          },
        })

        if (!user) {
          return
        }
        dispatchStep({
          state: {
            fundId: user?.funds[0]?.id,
            user,
          },
        })
      }

      setPaymentMethodLoading(true)
      const loginFirst = async (cb) => {
        const canProceedAsGuest = await checkEmailUse()

        if (isLoggedIn || canProceedAsGuest) {
          cb()
        } else {
          startLogin({
            prefillEmail: email,
            onCompleted: () => {
              cb()
              dispatchStep({ state: { isGuest: false } })
            },
            notification: (
              <>
                <MuiAlert severity="warning" sx={{ mt: 2 }}>
                  <MuiAlertTitle>Welcome back {email} </MuiAlertTitle>
                  Your donor information is protected by your user account. You&apos;ll need to log in with this email
                  to make a donation.
                </MuiAlert>
                <br />
              </>
            ),
          })
        }
      }

      const updatedStepperState = { ...stepperState, fundId: user?.funds[0]?.id, user }
      try {
        const paymentValues = await PaymentMethodMeta.getPaymentValues({
          paymentMethodApi,
          values: { paymentMethodType, ...payment, stepperState: updatedStepperState },
        })
        dispatchStep({
          state: {
            isGuest: !isLoggedIn,
            email,
            fundId: user?.funds[0]?.id,
            user,
            giftDetails: {
              ...(PaymentMethodMeta.amountType === 'securities'
                ? {
                    amountType: 'securities',
                    amount: 0,
                    securities,
                    addTip: false,
                    customTip: '',
                  }
                : {
                    amountType: PaymentMethodMeta.amountType ?? 'amount',
                    amount,
                    securities: [],
                    addTip,
                    customTip,
                    ...(PaymentMethodMeta.supportsRecurring === false ? { isRecurring: false } : {}),
                  }),
              purposeOfGrant,
              purposeOfGrantNote,
              recurringInterval,
              isRecurring: PaymentMethodMeta.supportsRecurring === false ? false : isRecurring,
              processingDate: PaymentMethodMeta.supportsScheduled === false && isLoggedIn ? null : processingDate,
              selfAttestation,
              charityId: charity.id,
            },
            payment: paymentValues,
          },
        })
      } finally {
        setPaymentMethodLoading(false)
      }

      if (!isLoggedIn) {
        await loginFirst(() => {
          dispatchStep({ action: 'NEXT' })
        })
      } else {
        dispatchStep({ action: 'NEXT' })
      }
    } catch (e) {
      setAlert({
        timeout: longWait,
        message: 'An error occurred while processing your information. Please try again.',
        error: e.message,
        severity: 'error',
      })
    }
  }

  const paymentMethodType = watch('paymentMethodType')
  const PaymentMethodMeta = !isLoggedIn
    ? GuestPaymentMethodsMeta[paymentMethodType] ?? {}
    : PaymentMethodsMeta[paymentMethodType] ?? {}

  useEffect(() => {
    const stepKey = `${isLoggedIn ? 'logged-in' : 'not-logged-in'}-${
      // treat wallet steps as credit steps, skips payment stepper page.
      paymentMethodType === 'credit-card' || paymentMethodType === 'wallet' ? 'credit' : 'not-credit'
    }`
    updateSteps(stepKey)
  }, [paymentMethodType, isLoggedIn, updateSteps])

  const [tipAmount, setTipAmount] = useState(0)
  useEffect(() => {
    setTipAmount(
      calculateTipAmount({
        amountType: PaymentMethodMeta.amountType ?? 'amount',
        ...getValues(),
      })
    )

    const subscription = watch((values) => {
      setTipAmount(
        calculateTipAmount({
          amountType: PaymentMethodMeta.amountType ?? 'amount',
          ...values,
        })
      )
    })

    return () => subscription.unsubscribe()
  }, [PaymentMethodMeta.amountType, getValues, watch])

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting} style={{ display: 'contents', border: 0, p: 0, m: 0 }}>
          <Stack spacing={2}>
            {isLoggedIn ? (
              <MuiAlert severity="info">
                <MuiAlertTitle>Welcome back, {profileName}</MuiAlertTitle>
                We will auto-fill donor info from your user account. You&apos;ll need to{' '}
                <Link
                  to="/logout"
                  onClick={(e) => {
                    logout({ skipNavigation: true })
                    e.preventDefault()
                    window.location.reload() // Reload the page
                  }}
                >
                  logout
                </Link>{' '}
                to make a donation under a different name.
              </MuiAlert>
            ) : (
              <ContentBox border>
                <Stack>
                  <Fields.Text
                    label="Email"
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...register('email', { ...validations.required, onBlur: checkEmailUse })}
                  />
                </Stack>
                {!canUseEmail && (
                  <MuiAlert severity="warning" sx={{ mt: 2 }}>
                    <MuiAlertTitle>Welcome back</MuiAlertTitle>
                    You&apos;ll need to{' '}
                    <Link
                      to="/login"
                      state={{ afterLogin: location.pathname }}
                      onClick={(e) => {
                        startLogin({
                          prefillEmail: getValues('email'),
                          onCompleted: () => {
                            // @note the useAuth does share its state so we have to make a fake navigation to re-render with the updated login state
                            const stepKey = `logged-in-${paymentMethodType === 'credit-card' ? 'credit' : 'not-credit'}`
                            updateSteps(stepKey)
                            navigate('.')
                          },
                        })
                        e.preventDefault()
                      }}
                    >
                      log in with this email
                    </Link>{' '}
                    to make a donation.
                  </MuiAlert>
                )}
              </ContentBox>
            )}
            <ContentBox border>
              <Stack spacing={2}>
                <PaymentMethodRadioList
                  control={control}
                  setValue={setValue}
                  fund={defaultFund}
                  hideRadio={!paymentMethodType}
                  isGuest={!isLoggedIn}
                  required
                />
                {paymentMethodType === 'credit-card' &&
                  PaymentMethodMeta.PaymentUI &&
                  (isLoggedIn ? true : canUseEmail) && (
                    <>
                      <Text.H6>Credit Card Details *</Text.H6>
                      <PaymentMethodMeta.PaymentUI
                        ref={paymentMethodApi}
                        {...{ me, defaultFund, register, control, formState, setAlert }}
                      />
                    </>
                  )}

                {PaymentMethodMeta.amountType === 'securities' ? (
                  <SecuritiesPayment.SecuritiesToTransferInKind required {...{ register, control, formState }} />
                ) : (
                  <>
                    <Text.H6>Gift Details *</Text.H6>
                    <Controller
                      control={control}
                      name="amount"
                      rules={{
                        required: 'This field is required',
                        // positive: (val) => parseFloat(val) > 0 || 'Must be a positive number',
                        min: { value: 1, message: 'Must be at least 1' },
                      }}
                      render={({ field, fieldState }) => (
                        <Fields.Text
                          {...field}
                          label="Amount"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputComponent: Fields.Amount,
                          }}
                        />
                      )}
                    />
                  </>
                )}
                {PaymentMethodMeta.supportsRecurring !== false && (
                  <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
                )}
                {PaymentMethodMeta.supportsScheduled !== false && isLoggedIn && (
                  <Scheduled
                    register={register}
                    errors={errors}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    entity="Gift"
                  />
                )}
                {PaymentMethodMeta.amountType !== 'securities' && (
                  <Stack spacing={3}>
                    <Controller
                      control={control}
                      name="addTip"
                      render={({ field }) => (
                        <Row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                value="yes"
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked)
                                }}
                              />
                            }
                            label="I want to help cover GiveWise’s transaction fees."
                          />
                          <Tooltip title="To help us continue spreading generosity, we appreciate a courtesy payment to cover transaction fees charged by payment providers. Your support truly makes a difference!">
                            {/* <Text.Body> */}
                            <Icons.Help />
                            {/* </Text.Body> */}
                          </Tooltip>
                        </Row>
                      )}
                    />
                    {isAddTipOpen && (
                      <Fields.Text
                        label="Courtesy Payment"
                        name="customTip"
                        error={!!errors?.customTip}
                        helperText={errors?.customTip?.message}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        value={watch('customTip') || tipAmount.toFixed(2)}
                        {...register('customTip', {
                          ...validations.number,
                        })}
                      />
                    )}
                  </Stack>
                )}
                <div
                  style={{
                    height: '1px',
                    width: '100%',
                    backgroundColor: 'darkgrey',
                    marginTop: '24px',
                  }}
                />
                <Text.Body>Purpose of Gift</Text.Body>
                <Stack>
                  <FormControl error={!!errors?.purposeOfGrant} fullWidth>
                    <InputLabel id="purposeOfGrantLabel">Please select the gift designation</InputLabel>
                    <Select
                      value={getValues('purposeOfGrant')}
                      aria-label="Please select the gift designation"
                      labelId="purposeOfGrantLabel"
                      label="Please select the gift designation"
                      {...register('purposeOfGrant')}
                      onChange={(e) => {
                        setValue('purposeOfGrant', e.target.value, { shouldValidate: true })
                      }}
                    >
                      {purposeOfGiftOptions()}
                    </Select>
                    {errors?.purposeOfGrant && <FormHelperText>{errors?.purposeOfGrant?.message}</FormHelperText>}
                  </FormControl>
                  {getValues('purposeOfGrant') && getValues('purposeOfGrant') !== 'Thanks for the work you do!' && (
                    <Fields.Text
                      label={purposeOfGrantNoteLabel}
                      name="purposeOfGrantNote"
                      error={!!errors?.purposeOfGrantNote}
                      helperText={errors?.purposeOfGrantNote?.message}
                      {...register('purposeOfGrantNote')}
                    />
                  )}
                </Stack>
                <Text.Bold>Self-attestation *</Text.Bold>
                <FormControl error={!!errors?.selfAttestation}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: errors?.selfAttestation ? palette.red : 'black',
                        }}
                        {...register('selfAttestation', { ...validations.required })}
                        checked={getValues('selfAttestation')}
                        onChange={(e) => {
                          setValue('selfAttestation', e.target.checked, { shouldValidate: true })
                        }}
                      />
                    }
                    label="I certify that my gift is for a permitted purpose
							  and will not result in a more than incidental benefit to me or persons related to me."
                  />
                  {errors?.selfAttestation && <FormHelperText>{errors?.selfAttestation?.message}</FormHelperText>}
                </FormControl>
                <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
              </Stack>
            </ContentBox>

            <Row spacing={2} justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || (isLoggedIn ? false : !canUseEmail)}
              >
                Continue
              </Button>
            </Row>
          </Stack>
        </fieldset>
      </form>

      {loginDialog}
    </Container>
  )
}
