import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router'

import { queries } from '../../graphql'
import { AppBar, Box, Button, Container, Icons, Link, Menu, MenuItem, NavTabs, Text, Toolbar, Typography } from '..'
import { useAuth } from '../../hooks'
import palette from '../../palette.json'

const userLinks = [{ to: '/profile', label: 'MyProfile' }]

export function Navbar() {
  const [{ isAdmin }, { logout }] = useAuth()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const { fundId } = useParams()
  const { data: { me: { fund: { id: mainFundId } = {} } = {} } = {} } = useQuery(queries.funds.myFund)
  const { data: { me: { funds = [] } = {} } = {} } = useQuery(queries.funds.myFunds)
  const { data: { me: { profile: { userType } = {} } = {} } = {} } = useQuery(queries.user.me)
  const currentFundId = fundId || mainFundId
  const charityId = useParams().id

  const fundLinks =
    userType === 'charity'
      ? [
          { to: `/charities/${charityId}`, label: 'Dashboard', 'data-testid': 'navBarMyGivingFundLink' },
          { to: `/charities/${charityId}/activity`, label: 'Activity' },
          { to: `/charities/${charityId}/designations`, label: 'Designations ' },
          { to: `/charities/${charityId}/direct-deposit`, label: 'Direct Deposit' },
          { to: `/charities/${charityId}/confirm`, label: 'Charity Profile' },
          { to: `/charities/${charityId}/invite-users`, label: 'Invite Users' },
          { to: `/charities/${charityId}/givegenius`, label: 'GiveGenius' },
        ]
      : [
          { to: `/funds/${currentFundId}`, label: 'My Giving Fund', 'data-testid': 'navBarMyGivingFundLink' },
          { to: `/funds/${currentFundId}/activity`, label: 'Activity' },
          { to: `/funds/${currentFundId}/contribute`, label: 'Add Funds' },
          { to: `/funds/${currentFundId}/grant`, label: 'Give' },
          { to: `/funds/${currentFundId}/share-funds-with-others`, label: 'Share Funds' },
          { to: `/funds/${currentFundId}/documents`, label: 'Tax Receipts' },
          ...(isAdmin ? [{ to: '/management', label: 'Manage' }] : []),
        ]

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleSignOut = async (_e) => {
    await logout()
  }

  return (
    <AppBar position="sticky">
      <Container
        maxWidth={userType === 'charity' ? '' : 'xl'}
        sx={{ mb: 0, paddingLeft: { xs: 0, md: 4 }, maxWidth: userType === 'charity' ? '80%' : 'auto' }}
      >
        <Toolbar disableGutters sx={{ justifyContent: 'flex-end' }}>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <NavTabs
              tabs={fundLinks}
              mobileMenuButton
              breakpoint="lg"
              TabIndicatorProps={{
                style: {
                  backgroundColor: palette.black,
                },
              }}
              tabProps={{
                sx: (theme) => ({
                  color: theme.palette.text.primary,
                  '&.Mui-selected': {
                    color: palette.black,
                  },
                }),
              }}
            />
          </Box>
          {funds.length > 1 && <FundSelectMenu funds={funds} />}
          <Box justifySelf="flex-end">
            <Button
              data-testid="avatarIcon"
              variant="contained"
              color="info"
              sx={{
                minWidth: 0,
                color: palette.light.blue,
                width: '40px!important',
                height: '40px',
                borderRadius: '20px',
                padding: 0,
              }}
              onClick={handleOpenUserMenu}
            >
              <Icons.Person />
              <Text.SR>user menu</Text.SR>
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {userLinks.map((link) => {
                let { to } = link

                if (userType === 'charity' && link.to === '/profile') {
                  to = `/charities/${charityId}/my-profile`
                }

                return (
                  <MenuItem key={link.to} onClick={handleCloseUserMenu}>
                    <Link to={to} lable={link.label}>
                      {link.label}
                    </Link>
                  </MenuItem>
                )
              })}
              <MenuItem key="signout" onClick={handleCloseUserMenu}>
                <Typography onClick={handleSignOut} data-testid="signOutLink">
                  Sign Out
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

function FundSelectMenu({ funds }) {
  const [anchorElFundMenu, setAnchorElFundMenu] = useState(null)

  const handleClick = (event) => {
    setAnchorElFundMenu(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElFundMenu(null)
  }

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        sx={{ color: palette.black, '&:hover': { color: palette.dark.blue }, fontSize: '0.875rem' }}
      >
        My Funds
        <Icons.ExpandMore />
      </Button>
      <Menu anchorEl={anchorElFundMenu} open={Boolean(anchorElFundMenu)} onClose={handleClose}>
        {funds.map((fund) => (
          <MenuItem onClick={handleClose} key={fund.id}>
            <Link to={`/funds/${fund.id}`}>
              {fund.name} - {fund.number}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
